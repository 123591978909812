.construction-page {
  width: 100vw;
  height: 100vh;
  background-color: black;
  color: white;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.falling-text::after {
  content: attr(data-text);
  display: inline-block;
  transform: rotate(-15deg) translateY(5px);
  transition: transform 0.3s ease;
}

.falling-text:hover::after {
  transform: rotate(-30deg) translateY(10px);
}